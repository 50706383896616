import React from 'react';
import NavBar from './NavBar';

// sound bite / library imports
import useSound from 'use-sound';
import youareachampion from '../audio/youareachampion.mp3'

// image imports 
import Depaulo from '../images/Depaulo.jpg';
import Plumber from '../images/Plumber.jpg';
import arrowdown from '../images/arrowdown.png';
import bracelet from '../images/bracelet.JPG';
import RyanArms from '../images/RyanArms.jpg'
import ryande3Dad from '../images/emotes/ryande3Dad.jpg';
import bubble from '../images/bubble.png';
import WPT from '../images/WPT.jpg';
import Results from '../images/Results.PNG';
import Colossus from '../images/Colossus.jpg';
import AboutTitleGif from '../images/AboutTitleGif.gif';
import AboutTitleGif2 from '../images/AboutTitleGif2.gif';
import laughing from '../images/laughing.jpg';
import Ring from '../images/Ring.png';
import RyanSmiling from '../images/RyanSmiling.jpg';
import JetsGame from '../images/JetsGame.jpg';

import Twitter from '../images/Twitter.png';
import Youtube from '../images/Youtube.png';
import Twitch from '../images/Twitch.png';
import Instagram from '../images/Instagram.png';

import styled from 'styled-components';
import '../styles.scss';

const AboutTitleImg = styled.img `
    margin: 0 auto;
    width: 30%;
    @media(max-width:1800px){
        width: 30%;
    }
    @media(max-width:1700px){
        width: 35%;
    }
    @media(max-width:1600px){
        width: 40%;
    }
    @media(max-width:1450px){
        width: 45%;
    }
    @media(max-width:1300px){
        width: 50%;
    }
    @media(max-width:1150px){
        width: 55%;
    }
    @media(max-width:1000px){
        width: 60%;
    }
    @media(max-width:850px){
        width: 65%;
    }
    @media(max-width:750px){
        width: 70%;
    }
    @media(max-width:650px){
        width: 75%;
    }
    @media(max-width:550px){
        width: 80%;
    }
    @media(max-width:500px){
        width: 85%;
    }
    @media(max-width:450px){
        width: 95%;
    }
`

const Img = styled.img `
    width: 45%;

    border-radius: 100%;
    @media(max-width: 1642px){
        width: 43%;
    }
    @media(max-width: 1196px){
        width: 40%;
    }
    @media(max-width: 1000px){
        width: 35%;
    }
    @media(max-width: 650px){
        width: 40%;
    }
`

const SecondImg = styled.img `
    width: 100%;
    border-radius: 10px;
    @media(max-width: 650px){
        width: 95%;
    }
`
const HendonImg = styled.img `
    width: 95%;
    margin: 1% auto;
    // border: 1px solid orange;
    border-radius: 30px;
    @media(max-width:650px){
        width:
    }


`
const HendonImg2 = styled.img `
    width: 75%;
    margin: 1% auto;
    // border: 1px solid orange;
    border-radius: 10px;

`

const Arrow = styled.img `
    width: 25%;
    animation: MoveUpDown 1s linear infinite;
    left: 0;
    bottom: 0;
    @keyframes MoveUpDown {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-15px);
        }
      }
    @media(max-width: 1800px){
        width: 16%;
        @keyframes MoveUpDown {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-13px);
            }
          }
    }
    @media(max-width: 1500px){
        width: 15%;
        @keyframes MoveUpDown {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }
    }
    @media(max-width: 1200px){
        width: 12%;
        @keyframes MoveUpDown {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }
    }
    @media(max-width: 650px){
        width: 9%;
        @keyframes MoveUpDown {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-5px);
            }
          }
    }
    @media(max-width: 500px){
        margin: 5px 0;
    }

    
`
const Div = styled.div `
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.927608543417367) 100%), url(${Depaulo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 5%;
    @media(max-width:1750px){
        padding-top: 5.5%;
    }
    @media(max-width:1650px){
        padding-top: 6.5%;
    }

    @media(max-width:1450px){
        padding-top: 7.5%;
    }
    @media(max-width:1250px){
        padding-top: 8.5%;
    }
    @media(max-width:950px){
        padding-top: 9.5%
    }
    @media(max-width:850px){
        padding-top: 10.5%;
    }
    @media(max-width:750px){
        padding-top 12%;
    }
    @media(max-width:650px){
        padding-top 14.5%;
    }
    @media(max-width:550px){
        padding-top: 16.5%;
    }
    @media(max-width:500px){
        padding-top: 18%;
    }
    @media(max-width:450px){
        padding-top: 20%;
    }
    @media(max-width:400px){
        padding-top: 22%;
    }
    @media(max-width:350px){
        padding-top: 25%;
    }
`

// const Div = styled.div `
//     background: linear-gradient(to bottom,rgba(22,22,22,.3) 0,rgba(22,22,22,.7) 75%,#161616 100%),url(${Depaulo});
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     width: 100%;
//     height: 100vh;
//     padding-top: 2.5%;
//     @media(max-width:1750px){
//         padding-top: 3%;
//     }
//     @media(max-width:1650px){
//         padding-top: 4%;
//     }

//     @media(max-width:1450px){
//         padding-top: 5%;
//     }
//     @media(max-width:1250px){
//         padding-top: 6%;
//     }
//     @media(max-width:950px){
//         padding-top: 7%
//     }
//     @media(max-width:850px){
//         padding-top: 8%;
//     }
//     @media(max-width:750px){
//         padding-top 9%;
//     }
//     @media(max-width:650px){
//         padding-top 10%;
//     }
//     @media(max-width:550px){
//         padding-top: 11%;
//     }
// `
const H2 = styled.h2 `
    font-family: 'Nothing You Could Do', cursive;
    font-size: 7rem;
    text-align: center;
    padding-top: 1%;
    color: #F05E23;
    @media(max-width: 1600px){
        font-size: 6rem;
    }
    @media(max-width: 750px){
        font-size: 4rem;
    }
    
`

const P = styled.p `
    font-size: 4rem;
    color: white;
    // border: 10px solid white;
    background: #F05E23;
    border-radius: 10px;
    padding: 1%;
    line-height: 1.5;
    @media(max-width: 1700px){
        font-size: 3.3rem;
    }
    @media(max-width: 1500px){
        font-size: 3.5rem;
    }
    @media(max-width: 1196px){
        font-size:3rem;
    }
    @media(max-width: 1000px){
        font-size:2.5rem;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
    }
    font-family: 'Zen Loop', cursive;
`

const P1 = styled.p `
    font-size: 4rem;
    color: #F05E23;
    border: 2px solid red;
    border-radius: 10px;
    width: 35%;
    text-align: center;
    line-height: 1.5;
    margin: 1% auto;
    @media(max-width: 1760px){
        font-size: 3.3rem;
        width: 40%;
    }
    @media(max-width: 1510px){
        font-size: 3.5rem;
        width: 45%
    }
    @media(max-width: 1196px){
        font-size:3rem;
    }
    @media(max-width: 1040px){
        font-size:2.5rem;
        width: 55%;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
        width: 45%
    }
    font-family: 'Zen Loop', cursive;
`

const P2 = styled.p `
    font-size: 4rem;
    color: white;
    border: 2px solid #8800be;
    background: #8800be;
    border-radius: 10px;
    width: 35%;
    text-align: center;
    line-height: 1.5;
    margin: 1% auto;
    animation-name: FadeInOut; 
    animation-iteration-count: infinite;
    animation-duration: 3s;
    @keyframes FadeInOut {
        0% {transform: scale(1);}
        25% {transform: scale(.97);}
        35% {transform: scale(.9);}
        45% {transform: scale(1.1);}
        55% {transform: scale(.9);}
        65% {transform: scale(1.1);}
        75% {transform: scale(1.03);}
        100% {transform: scale(1);}
    }

    // animation: MoveLeftRight 1s linear infinite;
    // @keyframes MoveLeftRight {
    //     0%, 100% {
    //       transform: translateX(5px);
    //     }
    //     50% {
    //       transform: translateX(-5px);
    //     }
    //   }
    
    @media(max-width: 1760px){
        font-size: 3.3rem;
        width: 40%;
    }
    @media(max-width: 1510px){
        font-size: 3.5rem;
        width: 45%
    }
    @media(max-width: 1196px){
        font-size:3rem;
    }
    @media(max-width: 1040px){
        font-size:2.5rem;
        width: 55%;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
        width: 45%
    }
    font-family: 'Zen Loop', cursive;
`

const Div3 = styled.div `
    display: flex;
    align-items: center;
    // border: 10px solid #FBFAF5;
    width: 45%;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    @media(max-width: 650px){
        width: 95%;
    }
`

const Div4 = styled.div `
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 45%;
    @media(max-width:650px){
        width: 95%;
    }
`
const Div5 = styled.div `
    display: flex;
    width: 45%;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    @media(max-width: 650px){
        width: 95%;
    }
`
const Div6 = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 45%;
    align-items: center;
    @media(max-width: 650px){
        width: 100%;
    }

`

const PicPar = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 2% auto;
    @media(max-width: 650px){
        flex-flow: column wrap;
        align-items: center;
    }
    border-bottom: 5px double #F05E23;
    // border-radius: 20px;
`
const PicPar2 = styled.div`
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% auto;
    padding-bottom: 2%;
    @media(max-width: 650px){
        flex-flow: column-reverse wrap;
        align-items: center;
    }
    border-bottom: 5px double #F05E23;
    // border-radius: 20px;
`

const PicPar3 = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 5px double #F05E23;
    // border-radius: 20px;
`

const PicPar4 = styled.div`
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% auto;
    border-bottom: 5px double #F05E23;
    // border-radius: 20px;
    @media(max-width: 650px){
        flex-flow: column-reverse wrap;
        align-items: center;
    }
`
const PicPar4sub = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    @media(max-width:650px){
        width: 95%;
    }
`

const Par4Img = styled.img`
    width: 75%;
    border-radius: 20px;
    margin: 4% auto;
    @media(max-width: 650px){
        width: 90%;
    }
`
const PicPar5 = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    // border-bottom: 5px double white;
    // border-radius: 20px;
`
const Emote = styled.img`
    width: 20%;
    height: 50%;
`
const Emote2 = styled.img`
    width: 33%;
    @keyframes fadeIn { 
        from { opacity: 0; } 
      }
    animation: fadeIn 2s infinite alternate;

`
const EmoteDiv = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    justify-content: center;
`

const MainAboutDiv = styled.div`
    background: black;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const YoutubeDiv = styled.div `
    width: 90%;
    margin: 5% auto;
    align-text: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(max-width:500px){
        margin: 0%;
    }
`
const ChampionButton = styled.button `
    font-size: 1.5rem;
    margin: 2%;
    width: 33%;
    border: 3px solid #F05E23;
    color: white;
    background: #522E76;
    border-radius: 20px;
    height: 7vh;
    text-align: center;
    outline: none;
`

const SocialMediaMain = styled.div `
    display: flex;
    flex-flow: column wrap;
    margin: 2% auto;
    margin-bottom: 0%;
    // black to orange
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 100%, rgba(0,0,0,1) 100%);
    // black to red
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(254,0,0,0.804359243697479) 100%, rgba(0,0,0,1) 100%);
    // border: 10px solid skyblue;
    // border-radius: 30px;
`
const SocialMedia = styled.div `
    display: flex;
    justify-content: space-evenly;
    margin: 2% auto;
    align-items: center;
    // title color blue middle background
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(135,206,250,1) 50%, rgba(0,0,0,1) 100%);
    // Site Orange middle background
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 50%, rgba(0,0,0,1) 100%);
`
const A1 = styled.a `
    width: 10%;
    height: 25%;
`
const A2 = styled.a `
    width: 25%;
    height: 25%;
`
const A3 = styled.div `
    width: 75%;
    margin: 0 auto;
`

const TabDiv = styled.div `
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1% 0;
`

const Tab = styled.button `
    width: 15%;
    border-radius: 15px;
    border: 3px solid white; 
    // background: ;
    background: #F05E23;
    color: white;
    font-size: 2rem;
    text-decoration: none;
    :hover{
        border: 3px solid #8800be;
    }
    :active{
        background: #8800be;
        // #F05E23;
    }
    @media(max-width:850px){
        font-size: 1.5rem;
    }
    @media(max-width:700px){
        font-size: 1rem;
    }
`
const TabA = styled.a `
    text-decoration: none;
    color: white;
    :hover{
        text-decoration: none;
        color: white;
    }
    
`

function About() {
    const [play] = useSound(youareachampion);

    return (
        
        <MainAboutDiv style={{backgroundColor: 'black'}}>
            <NavBar/>
            <Div>

            <AboutTitleImg src={AboutTitleGif}/>            
            </Div>
            <TabDiv>
                <Tab>
                    <TabA href="#vlog">How the Vlog Started </TabA>
                </Tab>
                <Tab>
                     <TabA href="#story">Ryan's First Poker Story</TabA>
                </Tab>
                <Tab>
                     <TabA href="#biggest">Ryan's Biggest Cash</TabA>
                </Tab>
                <Tab>
                     <TabA href="#bracelet">Ryan Wins A WSOP Bracelet</TabA>
                </Tab>
                <Tab>
                     <TabA href="#ring">Ryan Wins A WSOPC Circuit Ring</TabA>
                </Tab>
            </TabDiv>
            <H2 id="vlog"> How The Youtube Poker Vlog Started</H2>
            <PicPar>
               <Img src={Plumber}/> 
               <Div3>
                    <P>Ryan Depaulo Degenerate Gambler is a Team Pro for ACR (America's Card Room). He is a professional gambler, 
                        as well as an internet sensation within the gambling community. Ryan's claim to fame was one of his first videos, uploaded in 2018, 
                        titled, "FOUR Video Poker Jackpots in a Weekend *INSANE*". We were also first introduced to Joey "The Mush" Straub, a best 
                        friend of Ryans/gambling character in the vlogs. This video has since brought in over 150,000 views. Check it out below if you'd like! 
                    </P>
                    <Arrow src={arrowdown}/>
                    <div class="embed-responsive embed-responsive-16by9" style={{width: "100%"}}>
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/vdtBFLWiKRU" allowfullscreen></iframe>
                    </div>
                </Div3>
            </PicPar>
            <H2 id="story">Ryan Depaulo Degenerate Gambler's First Poker Story</H2>
            <PicPar2>
                <Div6>
                    <div class="embed-responsive embed-responsive-16by9" style={{width: "100%", margin: '2% 0'}}>
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/bhSqwpnDVKI" allowfullscreen></iframe>
                    </div>
                    <SecondImg style={{margin: "2% 0"}} src={laughing}/> 
                    {/* <ChampionButton onClick={play}>Tony's Post Tourney Speech</ChampionButton> */}
                </Div6>
                <Div3>
                    <P> Ryan's first poker tournament story is very interesting. He recalls the experience in a youtube vlog titled 
                        "The Story of My First Poker Tournament", shown in this section, where he created a stop-motion video re-enacting 
                        the night he played. In this video, he explains that he took a late night bus to Atlantic City on a Tuesday with a couple of 
                        friends and finds out that there was a $65 midnight nightly tourney called "Midnight Madness". He separates from his 
                        friends and girlfriend to the Taj Majal, where the tourney was held. Prior to playing, Ryan called his father who only said two 
                        words to him when he mentioned he was going to play a poker tournament, "Don't Punt", words that Ryan to this day has yet to 
                        listen to. Ryan ended up being the first one to bust running 
                        a straight into a boat, and since his only experience with tournies was livestreamed WPT and WSOP final tables, he stood up and 
                        proceeded to shake everyone's hand at the table, not realizing how much of a psycho he looked like. This moment still haunts Ryan
                        to this day as he relives the confused look on everyones faces from that night.
                        
                    </P>
                    <EmoteDiv >
                    <Emote src={ryande3Dad} style={{borderRadius: '50px'}}/>
                    <Emote2 src={bubble}/>
                    </EmoteDiv>
                </Div3>
            </PicPar2>
            <H2 id="biggest"> Ryan's Biggest Cash </H2>
            <PicPar3>
                <Div4>
                    <HendonImg src={WPT} alt="WPT Picture taken running deep"/>  
                    <HendonImg2 src={Results} style={{border:"3px solid orange;"}} alt="2021 Live Results So Far"/>
                </Div4>
                
                <Div5>
                    <P>Throughout the next decade Ryan considered himself as a recreational gambler. He mainly played micro-stakes poker 
                        tournaments online and small home games with friends. It wasn't until late 2016 that Ryan found a job working at a BroadWay Box Office, 
                        which allowed him to gamble on a much more regular basis. Ryan now has over $300,000 total live earnings recorded in the Hendonmob Database with his 2021 results shown here. 
                        His largest live tournament cash was a $208,643 payday for third place in a 17,000+ person field in the Colossus $400 WSOP Event in 2019 (Shown Below).
                    </P>
                    <HendonImg2 src={Colossus} alt="Picture Taken At Final Table of Colossus"/>
                </Div5>
            </PicPar3>
            <H2 id="bracelet"> Ryan Wins A WSOP Bracelet </H2>
            <PicPar4>
                <PicPar4sub>
                    <YoutubeDiv>
                        <P2 >Bracelet Winning Moment</P2>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/e2VIbHk3cjA" allowfullscreen></iframe>
                        </div>
                    </YoutubeDiv>
                    <Par4Img src={bracelet}/> 
                    <YoutubeDiv style={{marginBottom: '1%'}}>
                        <H2 style={{fontSize: '2rem', color: 'white'}}>Ryan Takes Fan to Jets Home Opener 2021</H2>
                        <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/lh0ZvGsBxWQ" allowfullscreen></iframe>
                        </div>
                    </YoutubeDiv>
                </PicPar4sub>
                <Div3>
                <P> During a Twitch stream in early 2020, Ryan was questioned by a Jets fan about the possiblity of winning a WSOP bracelet. He answered "If I ever win a WSOP bracelet, I will
                    personally fly you out to New York for opening day of the season". When the 2020 WSOP Bracelet Online Circuit began in July, Ryan told himself he would play every single 
                    event. After 11 failed events in a row where Ryan either min cashed or busted before the money, it was time to play Event 12, dubbed the Big 500 ($500 buy-in 2 re-entries). 
                    Ryan chose to avoid his usual airbnb or stay at a friends place in NJ, and chose the route of driving right over the bridge and finding a nearby plaza to park for the night. He 
                    found one with a Whole Foods that had free public wifi. After 2 failed bullets, one of which ended with a NFL Hall of Fame caliber Punt to fellow Poker Vlogger Brad Owen, 
                    Ryan finally managed to build up to 3x starting with his final re-buy as re-entries ended. He progressively built his stack, which eventually led to a final table. Ryan almost 
                    did all the busting out himself, with the final elimination being a former EPT Winner, Jack Salter (ScreenName: EarlGrey). The Final Hand was QQ vs AJo All in Pre, and the 
                    Queens held for the outright win of $159,563! Since Ryan only sold/swapped 20% with fellow poker players, this holds as the largest cash Ryan has ever had based on percentage 
                    he had of himself.
                </P>
                <P>
                    Ryan fulfilled his promise to the Jets Fan on Sunday, September 19, 2021 when the Jets finally had seats available for their home opener since in 2020 COVID restrictions limited stadium
                    capacity. Sadly since this is the Jets we're talking about, they ended up meeting all of the fan's expectations with a horrible 6 to 25 point loss to the New England Patriots, with 
                    new QB Zach Wilson throwing an impressive 4 interceptions as well as 0 touchdowns. Ryan ended up leaving the game in the 3rd quarter to beat the traffic. You can check that vlog out 
                    in this section!
                </P>
                <Par4Img style={{width: '35%'}} src={JetsGame}/> 
                </Div3>
            </PicPar4>
            <H2 id="ring"> Ryan Wins A WSOPC Circuit Ring </H2>
            <PicPar5>
                <Div4>
                    <HendonImg src={Ring} alt="WPT Picture taken running deep"/>  
                    <YoutubeDiv style={{marginBottom: '1%'}}>
                        <div class="embed-responsive embed-responsive-16by9">
                                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/p9yB7l2lAK4" allowfullscreen></iframe>
                        </div>
                    </YoutubeDiv>
                </Div4>
                
                <Div5>
                    <P>On August 24th, 2021, Ryan was playing a WSOPC ring series and ended up clicking buttons and somehow running deep in the WSOPC Online Event #4: Big 500 6-Max PLO. He managed to make it all the way to the 
                        final table, where his button clicking was accompanied by even more luck, as the chip leader at the time went from 1st to out in 4th in 3 hands, leaving Ryan to a smooth finish with chip 
                        lead final three. He ended up taking down the 147 person field for $16,681 and a WSOPC ring, which he made a video about that is shown in this section. He also ended up getting a great display 
                        item for the ring in the form of a hand sculpture, depicting the ring on the middle finger like the degen he is. 
                    </P>
                    <HendonImg src={RyanSmiling} alt="Ryan Smiling"/>  
                </Div5>
            </PicPar5>
            <SocialMediaMain>
                <H2 style={{color: '#87CEFA'}}>Socials</H2>
                <P1 style={{color:"#87CEFA", border: "none"}}>Throw Ryan Depaulo Generate Gambler a follow for updates!</P1>
                <SocialMedia>
                        <A2 href="https://www.youtube.com/user/Whyisjadakiss"><img src={Youtube}/></A2>
                        <A1 href="https://www.twitch.tv/ryandepaulodegenerate">
                            <img src={Twitch}/>
                        </A1>
                        <A1 href="https://twitter.com/depaulo_ryan"><img src={Twitter}/></A1>
                        <A1 href="https://www.instagram.com/ryan_depaulo/"><img src={Instagram}/></A1>
                </SocialMedia>
          </SocialMediaMain>
        </MainAboutDiv>
    )
}

export default About;