import React from 'react';
import NavBar from './NavBar';
import styled from 'styled-components';
import '../styles.scss';

// image imports
import Mushinbed from '../images/Mushinbed.jpg';
import GoofyJoey from '../images/GoofyJoey.jpg';
import JoeyThumbnail from '../images/JoeyThumbnail.jpg';
import Twitter from '../images/Twitter.png';
import Youtube from '../images/Youtube.png';
import Instagram from '../images/Instagram.png';
import MushTitleGif from '../images/MushTitleGif.gif';
import JoeySlots from '../images/JoeySlots.PNG';
import VictimMush from '../images/VictimMush.PNG';
import VictimMeter from '../images/VictimMeter.png';
import HeadsUp from '../images/HeadsUp.jpg';
import JoeyFunny from '../images/JoeyFunny.PNG';

const MainDiv = styled.div `
    background: linear-gradient(to bottom,rgba(22,22,22,.3) 0,rgba(22,22,22,.7) 80%,#000000 100%),url(${Mushinbed});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display:flex;
    flex-direction: column;
    // justify-content: space-between
    padding-top: 5.5%;
    @media(max-width:1850px){
        padding-top: 7%;
    }
    @media(max-width:1650px){
        padding-top: 8%;
    }

    @media(max-width:1450px){
        padding-top: 9%;
    }
    @media(max-width:1250px){
        padding-top: 11%;
    }
    @media(max-width:1050px){
        padding-top: 12%;
    }
    @media(max-width:950px){
        padding-top: 13%
    }
    @media(max-width:850px){
        padding-top: 15%;
    }
    @media(max-width:750px){
        padding-top 18%;
    }
    @media(max-width:650px){
        padding-top 20%;
    }
    @media(max-width:550px){
        padding-top: 24%;
    }
    @media(max-width:500px){
        padding-top: 27%;
    }
`
const MushTitleImg = styled.img `
    margin: 0 auto;
    width: 30%;
    @media(max-width:1800px){
        width: 30%;
    }
    @media(max-width:1700px){
        width: 35%;
    }
    @media(max-width:1600px){
        width: 40%;
    }
    @media(max-width:1450px){
        width: 45%;
    }
    @media(max-width:1300px){
        width: 50%;
    }
    @media(max-width:1150px){
        width: 55%;
    }
    @media(max-width:1000px){
        width: 60%;
    }
    @media(max-width:850px){
        width: 65%;
    }
    @media(max-width:750px){
        width: 70%;
    }
    @media(max-width:650px){
        width: 75%;
    }
    @media(max-width:550px){
        width: 80%;
    }
    @media(max-width:500px){
        width: 85%;
    }
    @media(max-width:450px){
        width: 95%;
    }
`

const MushFirstDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin 2% auto;
    padding-bottom: 2%;
    
    border-bottom: 5px double #F05E23;
    @media(max-width: 1200px){
        flex-flow: column wrap;
        align-items: center;
    }
`
const FirstImg = styled.img `
    width: 40%;
    // border: 5px solid #F05E23;
    border-radius: 10px;
    margin: 1% auto;
`
const MushP = styled.p `
    width: 95%;
    // display: flex;
    // align-items: center;
    text-align: center;
    font-size: 4rem;
    color: papayawhip;
    border: 2px solid #F05E23;
    border-radius: 10px;
    margin: 0 auto;
    background: #F05E23;
    line-height: 1.5;
    @media(max-width: 1700px){
        font-size: 3.3rem;
    }
    @media(max-width: 1500px){
        font-size: 3.5rem;
    }
    @media(max-width: 1200px){
        font-size:3rem;
    }
    @media(max-width: 1000px){
        font-size:2.5rem;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
    }
    font-family: 'Zen Loop', cursive;

`
const MushPBot = styled.p `
    width: 95%;
    // display: flex;
    // align-items: center;
    text-align: center;
    font-size: 4rem;
    color: papayawhip;
    margin: 0 auto;
    line-height: 1.5;
    @media(max-width: 1700px){
        font-size: 3.3rem;
    }
    @media(max-width: 1500px){
        font-size: 3.5rem;
    }
    @media(max-width: 1200px){
        font-size:3rem;
    }
    @media(max-width: 1000px){
        font-size:2.5rem;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
    }
    font-family: 'Zen Loop', cursive;
`

const MushFirstSub = styled.div `
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media(max-width: 1200px){
        flex-flow: column;
        align-items: center;
        width: 100%
    }
`
const MushSecondDiv = styled.div `
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin 2% auto;
    padding-bottom: 2%;

    border-bottom: 5px double #F05E23;
    @media(max-width: 1200px){
        flex-flow: column-reverse wrap;
        align-items: center;
    }
`

const MushSecondSub = styled.div `
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media(max-width: 1200px){
        flex-flow: column;
        align-items: center;
        width: 100%
    }
`
const MushSecondImg = styled.img `
    width: 95%;
    margin: 1% auto;
    // border: 1px solid orange;
    border-radius: 30px;
`

const MushSecondImg2 = styled.img `
    width: 45%;
    margin: 1% auto;
    border: 5px solid white;
    border-radius: 30px;
`
const MushSocials = styled.div `
    display: flex;
    flex-flow: column wrap;
    margin: 2% auto 0%;
    // black to orange
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 100%, rgba(0,0,0,1) 100%);
`

const MushSocialMedia = styled.div `
    display: flex;
    justify-content: space-evenly;
    margin: 2% auto;
    align-items: center;
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(135,206,250,1) 50%, rgba(0,0,0,1) 100%);
`

const A1 = styled.a `
    width: 10%;
    height: 25%;
`

const A2 = styled.a `
    width: 33%;
    height: 25%;
`
const H2 = styled.h1 `
    font-family: 'Nothing You Could Do', cursive;
    font-size: 8rem;
    text-align: center;
    // padding: 2% 0%;
    color: white;
    margin: 2%;
    color: #F05E23;
    @media(max-width: 1600px){
        font-size: 6rem;
    }
    @media(max-width: 750px){
        font-size: 4rem;
    }
`
const DivP = styled.div `
    display: flex;
    align-items: center;
    // border: 10px solid #FBFAF5;
    width: 45%;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    line-height: 1.5;
    @media(max-width: 1200px){
        width: 100%;
    }
`
const DivP2 = styled.div `
    display: flex;
    align-items: center;
    // border: 10px solid #FBFAF5;
    width: 45%;
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 1.5;
    @media(max-width: 1200px){
        width: 100%;
    }
`

const MushThirdDiv = styled.div `
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin 2% auto;
    padding-bottom: 2%;

    // border-bottom: 5px double white;
    @media(max-width: 1200px){
        flex-flow: column wrap;
        align-items: center;
    }
`
const MushTitleP = styled.p `
    font-size: 4rem;
    color: white;
    border: 2px solid #8800be;
    background: #8800be;
    border-radius: 10px;
    width: 95%;
    text-align: center;
    margin: 1% auto;
    line-height: 1.5;
    
    @media(max-width: 1760px){
        font-size: 3.3rem;
    }
    @media(max-width: 1510px){
        font-size: 3.5rem;
    }
    @media(max-width: 1196px){
        font-size:3rem;
    }
    @media(max-width: 1040px){
        font-size:2.5rem;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
    }
    font-family: 'Zen Loop', cursive;
`

const MushPictureP = styled.p `
    font-size: 4rem;
    color: white;
    border: 2px solid #F05E23;
    background: #F05E23;
    border-radius: 10px;
    width: 85%;
    text-align: center;
    margin: 1% auto;
    
    @media(max-width: 1760px){
        font-size: 3.3rem;
    }
    @media(max-width: 1510px){
        font-size: 3.5rem;
    }
    @media(max-width: 1196px){
        font-size:3rem;
    }
    @media(max-width: 1040px){
        font-size:2.5rem;
    }
    @media(max-width: 900px){
        font-size:2.2rem;
    }
    @media(max-width: 800px){
        font-size:1.9rem;
    }
    @media(max-width: 750px){
        font-size:1.8rem;
    }
    @media(max-width: 700px){
        font-size:1.7rem;
    }
    @media(max-width: 650px){
        font-size:2.5rem;
    }
    font-family: 'Zen Loop', cursive;
`

function Mush() {
    return (
        <div style={{background: 'black'}}>
            <NavBar/>
            <MainDiv>
                <MushTitleImg src={MushTitleGif}/>
            </MainDiv>
            <H2>Who Is Joey "The Mush" Straub?</H2>
            <MushFirstDiv>
                <DivP>
                    <MushP>Joey "The Mush" Straub is a best friend of Ryan Depaulo Degenerate Gambler and a gambling buddy of his as well. He has been a part of Ryan's vlog since the beginning,
                        making frequent guest experiences with their other friend Tom as well! Joey initially made his appearence in a vlog where he ended up hitting
                        4 jackpots ($4,000, $2,000, $1,000, $800) on video poker...and then never won a single jackpot / all in after. Some say every ounce of luck Joey 
                        had in his being was used up in that first vlog, and to this day he is still chasing that 'hit a jackpot' feel.
                        <br></br>
                        Ryan Depaulo has made an attempt recently to teach Joey his ways, showing him how to avoid getting "riv'd" (Rivered in Mush Speak) in all of his tournaments
                        and how to effectively win his all in's, something that joey has never been to do since that 4 Jackpot Trip. The exact montage where Ryan shows Joey 
                        how to become a better poker player is shown in this section, check it out for yourself!
                    </MushP>
                </DivP>
                <MushFirstSub>
                  <FirstImg src={JoeyFunny}/>
                  <div class="embed-responsive embed-responsive-16by9" style={{width: "90%"}}>
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/yQK__13PzeU?start=281" allowfullscreen></iframe>
                    </div>
                </MushFirstSub>
                
            </MushFirstDiv>
            <H2>The Mush Victim Meter</H2>
            <MushSecondDiv>
                <DivP>
                    <MushP>
                        Joey has had many other features in vlogs where he is being a mush. He always finds so much happiness in 
                        the idea of winning in video poker. The first picture in this section is of him as soon as he sits down 
                        for a video poker session, whereas the second picture is immediately after one of those sessions in the casino
                        cafeteria, where the mush is explaining how unlucky he is to Ryan. Ryan likes to rate this mush mode by using a 
                        degen "Mush Victim Meter", which is rated from 0 - 10. Now Joey is honestly incapable of holding a rating below 3
                        for more than a few minutes, so generally speaking it ranges from 3-10. The second picture indicates a mush level 
                        of around 9, with the possiblity of reaching 10 as ryan is a great aggravator for increasing  his Victim Level.
                    </MushP>
                    <MushSecondImg2 src={VictimMeter}/>
                </DivP>
                <MushSecondSub>
                    <MushSecondImg src={JoeySlots}/>
                    <MushSecondImg src={VictimMush}/>
                </MushSecondSub>
            </MushSecondDiv>
            <H2>How Did Ryan And The Mush Meet?</H2>
            <MushThirdDiv>
                <DivP2>
                    <MushTitleP>
                        I'm sure you're wondering at this point how this degenerate gambler and mush came to know each other. Well you scrolled down to 
                        the right section! 
                    </MushTitleP>
                    <MushP>
                        The first time Ryan and Joey laid eyes on each other was when the FT bubble burst of the $200 Daily 
                        $20k Guarentee at Borgata in late 2004. Joey, who was on an absolute heater, came into the Final Table as clear chip 
                        leader, having over 3x the stack of the person in second. Ryan came in with a baby stack of around 5 Big Blinds, and 
                        ended up grinding it all the way up to 2nd in chips after consecutive double ups through Joey. "Riv'd Again", Joey said
                        after a K hit the river in a 3 way pot between the final 3 players. Ryan, holding KJ hit his 3 outer against Joey's QQ, 
                        and the 3rd place finishers JJ. The next hand ended up being the last, as Ryan just open shoved 35bb with A3s, with a snap 
                        call coming from Joey's KK. Joey, with a slightly smaller chipstack fell to the ground when the A hit the river, eliminating 
                        him in second for a cash prize of $3555, while Ryan ended up taking home the trophy and $6235.
                    </MushP>
                </DivP2>
                <MushSecondSub>
                    <MushSecondImg src={HeadsUp}/>
                    <MushTitleP>
                        This picture was taken right before the fateful hand with Joey (Pictured left), already knowing that he was holding KK 
                        and just waiting to snap call Ryan's Shove (Pictured Right).
                    </MushTitleP>
                    <MushP>
                        Ryan Depaulo ended up lifting Joey up from the ground telling him he was sorry, and that he would go buy him a beer and dinner 
                        for crushing his soul that entire Final Table. Ryan knew that in order for him to have a successful poker career, he 
                        needed to keep the Mush around as he was great luck for him, as long as he was at least a poker table length away from 
                        him while he was gambling, thus creating the inevitable bond you see in the vlogs today.
                    </MushP>
                </MushSecondSub>
            </MushThirdDiv>
            <MushSocials>
                <H2 style={{color: '#87CEFA'}}>Socials</H2>
                <MushPBot style={{color:"#87CEFA", border: '0px solid black'}}>Throw them a follow for more of Joey the Mush!</MushPBot>
                <MushSocialMedia>
                        <A2 href="https://www.youtube.com/channel/UC4NkasgBJFCQFGC9hj1TD2w"><img src={Youtube}/></A2>
                        <A1 href="https://twitter.com/MushJoeythe"><img src={Twitter}/></A1>
                        <A1 href="https://www.instagram.com/joey_the_mush/"><img src={Instagram}/></A1>
                </MushSocialMedia>
          </MushSocials>
        </div>
    )
}

export default Mush;