import React, {useState} from 'react';
import styled from 'styled-components';
import mario from '../images/mario.jpg';
import Banner from '../images/banner.jpg';
import logo from '../images/logo.png';
import JokerLogo from '../images/JokerLogo.png';
import "../styles.scss";
import {Navbar} from 'react-bootstrap';


const Anchor = styled.a `
    width: 100%;
    color: #F05E23;
    // color: #fe0000;
    font-size: 2rem;
    text-decoration: none;
    padding: 0;
    margin: 0 auto;
    font-family: 'Press Start 2P', cursive;
    
    :hover {
        color: white;
        text-decoration: none;
        animation: blinker 2s linear infinite;
        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
        }
    :active {
        color: black;
        text-decoration: none;
    }
    @media(max-width:550px){
        font-size: 1.7rem;
    }
    @media(max-width:400px){
        font-size: 1.5rem;
    }
    @media(max-width:350px){
        font-size: 1.3rem;
    }
`
const Nav = styled.nav `
    position: absolute;
    display: flex;
    justify-content: space evenly;
    // border-top: 2px solid #F05E23;
    // border-left: 2px solid #F05E23;
    // border-bottom: 2px solid black;
    border: 2px solid black;
    // margin-top: 2px;
    
    box-shadow: 5px 5px 0px 2px black;
    padding: 0;
    width: 100%;
    // border-left: 2px solid #F05E23;
    // border-right: 5px solid #F05E23;
    // left to right orange to black
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 100%, rgba(0,0,0,1) 100%);
    // up to down orange to black
    // background: rgb(0,0,0);
    // background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 100%, rgba(0,0,0,1) 100%);
    // left to right red to black
    // background: rgb(0,0,0);
    // background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(254,0,0,1) 100%, rgba(0,0,0,1) 100%);
    border-radius: 30px 0px 0px 30px;
`

const Div = styled.div `
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    margin-right: 3%;
    @media(max-width:1600px){
        width: 30%
    }
    @media(max-width:1200px){
        width: 40%
    }
    @media(max-width:1000px){
        width: 40%
    }
    @media(max-width:875px){
        width: 50%
    }
    @media(max-width:600px){
        width: 65%
    }
`

const Img = styled.img `
    margin-left: 2px;
    width: 3.5%;
    // height: 6.5vh;
    height: 60px;
    // border: 2px solid #F05E23;
    // background: black;
    border-radius: 30px;
    padding: 2px;
    // black to orange left to right
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 100%, rgba(0,0,0,1) 100%);
    // black to red left to right
    // background: rgb(0,0,0);
    // background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(254,0,0,1) 100%, rgba(0,0,0,1) 100%);
    @media(max-width:1600px){
        width: 4.5%;
    }
    @media(max-width:1400px) {
        width: 5%;
    }
    @media(max-width:1200px){
        width: 6%;
    }
    @media(max-width:1000px){
        width: 7%;
    }
    @media(max-width:900px){
        width: 8%;
    }
    @media(max-width:800px){
        width: 9%;
    }
    @media(max-width:700px){
        width: 10%;
    }
    @media(max-width:600px){
        width: 11%;
    }
    @media(max-width:500px){
        width: 13%;
    }
    @media(max-width:450px){
        width: 15%;
    }
    @media(max-width: 400px){
        width: 17%;
    }
    @media(max-width: 350px){
        width: 20%;
    }
    :hover {
        // animation to spin logo
        // border: 2px solid skyblue;
        transform: scale(1.05);
        // top: 50%;
        // left: 50%;
        // -webkit-animation:spin 10s linear infinite;
        // -moz-animation:spin 10s linear infinite;
        // animation:spin 10s linear infinite;
        // @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
        // @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
        // @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }  
    }
`
const H1 = styled.h1`
    // font-size: 2rem;
    opacity: 0.3;
    color: #F05E23;
    // font-family: 'Press Start 2P', cursive;
    @media(max-width: 950px){
        display: none;
        
    }
`

const NavBar = () => {
    return (
        <Nav className='navbar'>
            <Img src={JokerLogo}/>
            <H1 style={{opacity: "0.01"}}>Ryan Depaulo Degenerate Gambler</H1>
            <Div>
               <Anchor href="/">Home</Anchor>
               <Anchor href="/mush">Mush</Anchor>
                <Anchor href="/about">About</Anchor>
            </Div>
        </Nav>
    );
};

export default NavBar;