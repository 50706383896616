import React from 'react';

const KEY = 'AIzaSyCICTPG2Al7ALQKSck6DHsk3d4fhJ6Nwzk';
const CHANNEL_ID = 'UClHTtNkaedA5x0aWedOnauw';
const PLAYLIST_ID = 'PLnPCeeS2JRnbfp2muX9Oj3Hg64QtrTXMn';
const POPULAR_VIDS = [
    {},
    {},
    {},
    {}
];
const COLOSSUS_VIDS = [
    {etag: "nlSOul7lHL9FIBvLmOKZgRq72Ws",
    id: "UExuUENlZVMySlJuYmZwMm11WDlPajNIZzY0UXRyVFhNbi41NkI0NEY2RDEwNTU3Q0M2",
    kind: "youtube#playlistItem",
    snippet: 
        {channelId: "UClHTtNkaedA5x0aWedOnauw",
        channelTitle: "Ryan Depaulo: Degenerate Gambler",
        description: "Pt 1 of My epic colossus Run at the 2019 WSOP where I get a 6 figure score. From Las Vegas this Gambling Vlog and poker Vlog I hit a video poker jackpot and play day 1 of the colossus. Also met Joe Ingram and wine about Doug Polk more cause my butt hurt.\n\n\nBEST WAY TO REACH ME IS:\nhttps://www.instagram.com/ryan_depaulo/?hl=en\n\nPoker Coaching:\n   \nI Stream on Twitch!\nhttps://www.twitch.tv/ryandepaulodegenerate\n\n\nCAITYS INSTAGRAM: https://www.instagram.com/cmard27/",
        playlistId: "PLnPCeeS2JRnbfp2muX9Oj3Hg64QtrTXMn",
        position: 0,
        title: "My EPIC Colossus Run! Pt.1 (Gambling Vlog #53)",
        publishedAt: "2019-08-30T17:11:04Z",
            thumbnails: {default: {url: "https://i.ytimg.com/vi/CoA_tt7iCOU/default.jpg", width: 120, height: 90},
            high: {url: "https://i.ytimg.com/vi/CoA_tt7iCOU/hqdefault.jpg", width: 480, height: 360},
            maxres: {url: "https://i.ytimg.com/vi/CoA_tt7iCOU/maxresdefault.jpg", width: 1280, height: 720},
            medium: {url: "https://i.ytimg.com/vi/CoA_tt7iCOU/mqdefault.jpg", width: 320, height: 180},
            standard: {url: "https://i.ytimg.com/vi/CoA_tt7iCOU/sddefault.jpg", width: 640, height: 480}
    }}
    },
    {snippet: {
        channelId: "UClHTtNkaedA5x0aWedOnauw",
        channelTitle: "Ryan Depaulo: Degenerate Gambler",
        description: "2019 Wsop. Colossus. Day 2. How I turned $400 into $208,000 my biggest win ever. The World Series of poker 2019 from Las Vegas. In this Gambling Vlog and poker Vlog I am deep in the colossus which is the biggest poker tournament in the world every year. 13,109 entrants and 1,965. \n\nI STREAM ON TWITCH: \nhttps://www.twitch.tv/ryandepaulodege...\n\nINSTAGRAM:\nhttps://www.instagram.com/ryandepaulo...\n\nTWITTER:\nhttps://twitter.com/depaulo_ryan?ref_...\n\nCAITYS INSTAGRAM: \nhttps://www.instagram.com/cmard27/\n\nELITE POKER COACHING!: \nhttps://t2m.io/zTvTkQvs\n\nAMERICA'S CARDROOM \nSign Up Bonus Code: GAMBLER   https://t2m.io/Gambler\n\nVIDEO EDITING & GRAPHICS BY.....\n   ME BRO!",
        playlistId: "PLnPCeeS2JRnbfp2muX9Oj3Hg64QtrTXMn",
        position: 1,
        title: "Part 2 (Gambling Vlog#54)",
        publishedAt: "2019-08-30T17:11:04Z",
        thumbnails: {
            default: {url: "https://i.ytimg.com/vi/l022ds_l2IU/default.jpg", width: 120, height: 90},
            high: {url: "https://i.ytimg.com/vi/l022ds_l2IU/hqdefault.jpg", width: 480, height: 360},
            medium: {url: "https://i.ytimg.com/vi/l022ds_l2IU/mqdefault.jpg", width: 320, height: 180}
        },
    }},     
    {snippet: {
        channelId: "UClHTtNkaedA5x0aWedOnauw",
        channelTitle: "Ryan Depaulo: Degenerate Gambler",
        description: "2019 Wsop. Colossus. Day 3. Amazing Poker win. How I turned $400 into $208,000 my biggest win ever. The World Series of poker 2019 from Las Vegas. In this Gambling Vlog and poker Vlog I am deep in the colossus which is the biggest poker tournament in the world every year. 13,109 entrants and 109 remain. We made the final table!\n\nPlease Subscribe, Please Like! It helps for real!\n\nMike Postle Parody Rap: \nhttps://youtu.be/QQRYaXV_Gfo\n\nINSTAGRAM:\nhttps://www.instagram.com/ryan_depaulo/?hl=en\n\nTWITTER:\nhttps://twitter.com/depaulo_ryan?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor\n\nCAITYS INSTAGRAM: https://www.instagram.com/cmard27/\n\nJoey The Mush on Instagram:\nhttps://www.instagram.com/joey_the_mush/?hl=en\n\nI Stream on Twitch!\nhttps://www.twitch.tv/ryandepaulodegenerate",
        playlistId: "PLnPCeeS2JRnbfp2muX9Oj3Hg64QtrTXMn",
        position: 2,
        title: "Part 3 (Gambling Vlog #55)",
        publishedAt: "2019-08-30T17:11:04Z",
        resourceId: {kind: "youtube#video", videoId: "fWjeKJ8OYAM"},
        thumbnails: {
            default: {url: "https://i.ytimg.com/vi/fWjeKJ8OYAM/default.jpg", width: 120, height: 90},
            high: {url: "https://i.ytimg.com/vi/fWjeKJ8OYAM/hqdefault.jpg", width: 480, height: 360},
            maxres: {url: "https://i.ytimg.com/vi/fWjeKJ8OYAM/maxresdefault.jpg", width: 1280, height: 720},
            medium: {url: "https://i.ytimg.com/vi/fWjeKJ8OYAM/mqdefault.jpg", width: 320, height: 180},
            standard: {url: "https://i.ytimg.com/vi/fWjeKJ8OYAM/sddefault.jpg", width: 640, height: 480}
        },
    }},  
    {
        snippet: {
            channelId: "UClHTtNkaedA5x0aWedOnauw",
            channelTitle: "Ryan Depaulo: Degenerate Gambler",
            description: "This is the pt.3 of how I turned $400 into $208,000 at the 2019 WSOP. In this poker vlog and gambling vlog we see the conclusion of my 2019 colossus run. we are at the final table, in the last part of the amazing poker tournament trip from las vegas.\n\nTHANKS TO JEFF BOSKI FOR HELP:\nhttps://www.youtube.com/channel/UC8ydBWZPsbA3jkoeDVZW5VA\n\nAMERICA'S CARDROOM \nSign Up Bonus Code: GAMBLER   https://t2m.io/Gambler\n\nI STREAM ON TWITCH: \nhttps://www.twitch.tv/ryandepaulodege...\n\nINSTAGRAM:\nhttps://www.instagram.com/ryandepaulo...\n\nTWITTER:\nhttps://twitter.com/depaulo_ryan?ref_...\n\nCAITYS INSTAGRAM: \nhttps://www.instagram.com/cmard27/\n\nELITE POKER COACHING!: \nhttps://t2m.io/zTvTkQvs\n\nVIDEO EDITING & GRAPHICS BY.....\n   ME BRO!",
            playlistId: "PLnPCeeS2JRnbfp2muX9Oj3Hg64QtrTXMn",
            position: 3,
            title: "Part 4 Final Table (Gambling Vlog#56)",
            publishedAt: "2019-08-30T17:11:04Z",
            resourceId: {kind: "youtube#video", videoId: "kbxJac_kbAY"},
            thumbnails: {
                default: {url: "https://i.ytimg.com/vi/kbxJac_kbAY/default.jpg", width: 120, height: 90},
                high: {url: "https://i.ytimg.com/vi/kbxJac_kbAY/hqdefault.jpg", width: 480, height: 360},
                maxres: {url: "https://i.ytimg.com/vi/kbxJac_kbAY/maxresdefault.jpg", width: 1280, height: 720},
                medium: {url: "https://i.ytimg.com/vi/kbxJac_kbAY/mqdefault.jpg", width: 320, height: 180},
                standard: {url: "https://i.ytimg.com/vi/kbxJac_kbAY/sddefault.jpg", width: 640, height: 480}
            }

        }

    }
]

export {KEY, CHANNEL_ID, PLAYLIST_ID, POPULAR_VIDS, COLOSSUS_VIDS};
