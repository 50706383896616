import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import NavBar from './NavBar';

import AOS from 'aos';
import "aos/dist/aos.css";

import styled from 'styled-components';
import {Card, Button} from 'react-bootstrap';
import useSound from 'use-sound';
import joeyisamush from '../audio/Joeyisamush.mp3'
import {
    KEY, 
    CHANNEL_ID,
    PLAYLIST_ID,
    POPULAR_VIDS, 
    COLOSSUS_VIDS} from './data';
import '../styles.scss';
import axios from 'axios';
// images
import DepauloHome from '../images/DepauloHome.jpg';
import DepauloHome1 from '../images/DepauloHome1.jpg';
import ryande3Angle from '../images/emotes/ryande3Angle.jpg';
import ryande3C from '../images/emotes/ryande3C.jpg';
import ryande3Cay from '../images/emotes/ryande3Cay.jpg';
import ryande3Dad from '../images/emotes/ryande3Dad.jpg';
import ryande3Luke from '../images/emotes/ryande3Luke.jpg';
import ryande3M from '../images/emotes/ryande3M.jpg';
import ryande3P from '../images/emotes/ryande3P.jpg';
import ryande3Punt from '../images/emotes/ryande3Punt.jpg';
import ryande3Sanic from '../images/emotes/ryande3Sanic.jpg';
import ryande3Stu from '../images/emotes/ryande3Stu.jpg';
import ryande3T from '../images/emotes/ryande3T.jpg';
import ryande3Tu from '../images/emotes/ryande3Tu.jpg';
import ryande3Wow from '../images/emotes/ryande3Wow.jpg';
import ryande3Y from '../images/emotes/ryande3Y.jpg';
import ryande32 from '../images/emotes/ryande32.jpg';
import ryande33 from '../images/emotes/ryande33.jpg';
import ryande34 from '../images/emotes/ryande34.jpg';
import ryande35 from '../images/emotes/ryande35.jpg';
import Twitter from '../images/Twitter.png';
import Youtube from '../images/Youtube.png';
import Twitch from '../images/Twitch.png';
import Instagram from '../images/Instagram.png';
import Hendon from '../images/Hendon.PNG';
import Results from '../images/Results.PNG';
import Americas_Cardroom from '../images/Americas_Cardroom.png';
import Americas_Cardroom1 from '../images/Americas_Cardroom1.jpg';
import merch from '../images/merch.jpg';
import twitchwall from '../images/twitchwall.png';
import poker from '../images/poker.jpg';
import ryancheer from '../images/ryancheer.jpg';
import neontwitch from '../images/neontwitch.png';
import WSOP from '../images/WSOP.jpg';
import DepauloTitleGif from '../images/DepauloTitleGif.gif';
import MerchTitleGif from '../images/MerchTitleGif.gif';
import PatreonLogo from '../images/PatreonLogo.png';
import PatreonLogo2 from '../images/PatreonLogo2.png';
import PodcastPic from '../images/PodcastPic.PNG';
import PodcastPic2 from '../images/PodcastPic2.png';
import PodcastPic3 from '../images/PodcastPic3.png';
import congrats from '../images/congrats.gif';
import JokerLogo from '../images/JokerLogo.png';
import AcrPoker from '../images/acr-poker.jpg';

const Img = styled.img `
    width: 4.5%;
    height: 5%;
    margin: 0.5% 1%;
    border-radius: 45px;
    @media(max-width: 1600px){
        width: 5.5%;
        height: 6%;
    }
    @media(max-width: 1200px){
        width: 6.3%;
        height: 7.5%;
    }
    @media(max-width:1000px){
        width:7%;
        height:9%;
    }
    @media(max-width:750px){
        width:10%;
        height:13%;
    }
    @media(max-width:500px){
        width:12%;
        height:17%;
    }
    @media(max-width:400px){
        width:14%;
        height:19%;
    }
`

const Div = styled.div `
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 97%), url(${DepauloHome1});
    // background: linear-gradient(180deg, rgba(0,0,0,0.8379726890756303) 0%, rgba(0,0,0,0.10688025210084029) 22%, rgba(0,0,0,1) 98%), url(${DepauloHome1});
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.10688025210084029) 22%, rgba(0,0,0,1) 98%), url(${DepauloHome});
    // background: linear-gradient(to bottom,rgba(22,22,22,.3) 0,rgba(22,22,22,.7) 80%,#000000 100%),url(${DepauloHome});
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5%;
    @media(max-width:1800px){
        padding-top: 6.5%;
    }
    @media(max-width:1700px){
        padding-top: 7%;
    }

    @media(max-width:1600px){
        padding-top: 7.5%;
    }
    @media(max-width:1450px){
        padding-top: 8.5%;
    }
    @media(max-width:1300px){
        padding-top: 9.5%
    }
    @media(max-width:1100px){
        padding-top: 10.5%;
    }
    @media(max-width:1000px){
        padding-top: 11.5%;
    }
    @media(max-width:900px){
        padding-top: 13%;
    }
    @media(max-width:850px){
        padding-top: 15%;
    }
    @media(max-width:800px){
        padding-top: 17%;
    }
    @media(max-width:750px){
        padding-top: 19%;
    }
    @media(max-width:700px){
        padding-top: 21%;
    }
    @media(max-width:650px){
        padding-top: 23%;
    }
    @media(max-width:600px){
        padding-top: 25%;
    }
    @media(max-width:550px){
        padding-top: 27%;
    }
    @media(max-width:450px){
        padding-top: 30%;
    }
    @media(max-width:400px){
        padding-top: 33%;
    }
    @media(max-width:350px){
        padding-top: 37%;
    }
`
const TitleImg = styled.img `
    margin: 0 auto;
    width: 30%;
    @media(max-width:1800px){
        width: 30%;
    }
    @media(max-width:1700px){
        width: 35%;
    }
    @media(max-width:1600px){
        width: 40%;
    }
    @media(max-width:1450px){
        width: 45%;
    }
    @media(max-width:1300px){
        width: 50%;
    }
    @media(max-width:1150px){
        width: 55%;
    }
    @media(max-width:1000px){
        width: 60%;
    }
    @media(max-width:850px){
        width: 65%;
    }
    @media(max-width:750px){
        width: 70%;
    }
    @media(max-width:650px){
        width: 75%;
    }
    @media(max-width:550px){
        width: 80%;
    }
    @media(max-width:500px){
        width: 85%;
    }
    @media(max-width:450px){
        width: 95%;
    }
`
const Button1 = styled.button `
    width: 20%;
    margin: 1% auto;
    cursor: pointer;
	position: relative;
	padding: 2.5rem 7.5rem;
	border-radius: 3.75rem;
	line-height: 1rem;
	font-size: 2rem;
	font-weight: 600;
    border: 1px solid black;
	background-image: linear-gradient(-180deg, #c0571f 0%, #c01f2f 100%);
    // background-image: linear-gradient(-180deg, #c0571f 0%, #522E76 100%);
    outline: none;
    span {
        color: transparent;
        // pink to light pink
        background-image: linear-gradient(0deg, #EE82DA 0%, #FEFAFD 100%);
        // dark red to light red
        // background-image: linear-gradient(0deg, #ffffff 0%, #c4c4c4 100%);
        -webkit-background-clip: text;
        background-clip: text;
        filter: drop-shadow(0 2px 2px hsla(290, 100%, 20%, 1));
    }
    :before {
        content: "";
        display: block;
        height: 0.25rem;
        position: absolute;
        top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 7.5rem);
        background: #fff;
        border-radius: 100%;
        opacity: 0.7;
	    background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%);
    }
    :after {
        content: "";
        display: block;
        height: 0.25rem;
        position: absolute;
        bottom: 0.75rem;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 7.5rem);
        background: #fff;
        border-radius: 100%;
        
        filter: blur(1px);
        opacity: 0.05;
        background-image: linear-gradient(-270deg, rgba(255,255,255,0.00) 0%, #FFFFFF 20%, #FFFFFF 80%, rgba(255,255,255,0.00) 100%);
    }
    @media(max-width:1550px){
        width: 27%;
    }
    @media(max-width:1082px){
        width: 35%;
    }
    @media(max-width:840px){
        width: 45%;
    }
    @media(max-width:657px){
        width: 65%;
    }
    @media(max-width:500px){
        width: 85%;
    }
`

const H1 = styled.h1 `
    font-family: 'Press Start 2P', cursive;
    font-size: 5rem;
    text-align: center;
    color: white;
    padding-top: 1%;
`
const H2 = styled.h2 `
    font-family: 'Press Start 2P', cursive;
    font-size: 4rem;
    text-align: center;
    // padding: 2% 0%;
    color: white;
    margin: 2%;
    // animation: blinker 3s linear infinite;
    // @keyframes blinker {
    //     50% {
    //         opacity: 0;
    //     }
    // }
    @media(max-width: 1600px){
        font-size: 2.7rem;
    }
    @media(max-width: 1000px){
        font-size: 2.0rem;
    }
    @media(max-width:600px){
        font-size: 1.5rem;
        margin: 2% 1%;
    }
`
const TwitchH2 = styled.h2 `
    font-family: 'Press Start 2P', cursive;
    font-size: 4rem;
    text-align: center;
    color: white;
    margin: 1%;
    // animation: blinker 3s linear infinite;
    // @keyframes blinker {
    //     50% {
    //         opacity: 0;
    //     }
    // }
    // @media(max-width: 1600px){
    //     font-size: 2.7rem;
    // }
    // @media(max-width: 1000px){
    //     font-size: 2.0rem;
    // }
    @media(max-width:600px){
        font-size: 3.5rem;
        margin: 2% 1%;
    }
    @media(max-width:500px){
        font-size: 3rem;
    }
`
const TwitchTitleDiv = styled.div`
    // background: black;
    height: 100%;
    // display: flex;
    // flex-direction: row;
    // align-items: flex-start;

`

const H3 = styled.h3 `
    font-family: 'Press Start 2P', cursive;
    font-size: 3rem;
    padding: 1% 0%;
    color: red;
    text-align: center;
    // animation: blinker 3s linear infinite;
    // @keyframes blinker {
    //     50% {
    //         opacity: 0;
    //     }
    // }
    @media(max-width:500px){
        font-size: 2rem;
        margin: 2% 1%;
    }
`

const YoutubeDiv = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: black;
    padding-bottom: 2%;
    background-image: url(${ryancheer});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media(max-width:1400px){
        display: none;
    }
`
const YoutubeDiv710 = styled.div`
    display: none;
    @media(max-width: 1400px){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        background:white;
        background-image: url(${ryancheer});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
`

const VideosDiv = styled.div `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    // margin: 0% auto;
`

const TwitchTv = styled.div `
    width: 100%;
`
const DivSide = styled.div `
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(max-width:1300px){
        width:100%;
        align-items: center;
    }
    // -webkit-animation: slideIn 2s forwards;
    // -moz-animation: slideIn 2s forwards;
    // animation: slideIn 2s forwards;
    // @-webkit-keyframes slideIn {
    //     0% {
    //       transform: translateX(-900px);
    //     }
    //     100% {
    //       transform: translateX(0);
    //     }
    //   }
    //   @-moz-keyframes slideIn {
    //     0% {
    //       transform: translateX(-900px);
    //     }
    //     100% {
    //       transform: translateX(0);
    //     }
    //   }
    //   @keyframes slideIn {
    //     0% {
    //       transform: translateX(-900px);
    //     }
    //     100% {
    //       transform: translateX(0);
    //     }
    //   }
      
`
const DivBoth = styled.div `
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    
    margin: 0 auto;
    margin-bottom: 2%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(197,69,69,0.700717787114846) 50%, rgba(0,0,0,1) 80%);

    @media(max-width:1300px){
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background: black;
        margin-bottom: 5%;
        
    }

`
const MainerVid = styled.div`
    width: 50%;
    margin: 1% auto;
    align-text: center;
    @media(max-width:1300px){
        width: 95%;
    }
    @media(max-width:500px){
        margin: 0%;
    }
`
const MainVid = styled.div `
    width: 85%;
    margin: 1% auto;
    align-text: center;
    @media(max-width:1300px){
        width: 95%;
    }
    @media(max-width:500px){
        margin: 0%;
    }
`
const SocialMedia = styled.div `
    display: flex;
    justify-content: space-evenly;
    margin: 2% auto;
    align-items: center;
    // title color blue middle background
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(135,206,250,1) 50%, rgba(0,0,0,1) 100%);
    // Site Orange middle background
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 50%, rgba(0,0,0,1) 100%);
`
const SocialMediaMain = styled.div `
    display: flex;
    flex-flow: column wrap;
    margin: 2% auto;
    margin-bottom: 0%;
    // black to orange
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(240,94,35,1) 100%, rgba(0,0,0,1) 100%);
    // black to red
    // background: rgb(0,0,0);
    // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(254,0,0,0.804359243697479) 100%, rgba(0,0,0,1) 100%);
    // border: 10px solid skyblue;
    // border-radius: 30px;
`
const A1 = styled.a `
    width: 10%;
    height: 25%;
`
const A2 = styled.a `
    width: 25%;
    height: 25%;
`
const A3 = styled.div `
    width: 75%;
    margin: 0 auto;
`

const P1 = styled.p `
    color: white; 
    font-size: 3rem;
    text-align: center;
    margin: 0 1%;
    @media(max-width:500px){
        font-size: 2rem;

    }
`
const TwitchP1 = styled.p `
    color: white; 
    font-size: 3rem;
    text-align: center;
    margin: 0 1%;
    font-family: 'Bebas Neue', cursive;
    @media(max-width:500px){
        font-size: 2rem;

    }
`
const TwitchH1 = styled.p `
    color: white; 
    font-size: 3rem;
    text-align: center;
    margin: 0 1%;
    font-family: 'Bebas Neue', cursive;
    font-family: 'Dosis', sans-serif;
    @media(max-width:500px){
        font-size: 2rem;

    }
`
const HendonDiv = styled.div`
    width:43%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    @media(max-width:1000px){
        width: 90%;
        flex-direction: column;
    };
`
const ResultsDiv = styled.div`
    margin: 2% auto;
    display: flex;
    flex-direction: row;
    @media(max-width:1000px){
        flex-direction: column;
    }
    // background:white;
    // background-image: url(${poker});
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    padding-bottom: 2%;
`
const Affiliates = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 2% auto;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    @media(max-width:700px){
        flex-direction: column;
    }
`
const AffiliateImg = styled.img`
    width: 50%;
    @media(max-width:700px){
        width: 100%;
    }
`
const AffiliateSpan = styled.span`
    color: red;
    font-size: 4rem;
    :hover {
        text-decoration: underline;
        text-decoration-color: red;
    }
    @media(max-width:700px){
        width: font-size: 3rem;
    }
`

const AffiliateH2 = styled.h2`
    color: white;
    font-family: 'Press Start 2P', cursive;
    font-size: 4rem;
    text-align: center;
    // padding: 2% 0%;
    margin: 2%;
    // animation: blinker 3s linear infinite;
    // @keyframes blinker {
    //     50% {
    //         opacity: 0;
    //     }
    // }
    @media(max-width:700px){
        font-size: 2.7rem;
        margin: 2% 1%;
    }
`

const MerchDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% auto;
    padding: 0 3%;

    @media(max-width:1365px){
        flex-direction: column;
        // border: 5px solid #522E76;
        // border-radius: 25px;
    }
    // Black Top - Orange Bottom
    // background: rgb(2,0,36);
    // background: linear-gradient(180deg, rgba(2,0,36,1) 65%, rgba(240,94,35,1) 100%, rgba(0,212,255,1) 100%);
    // Orange Top - Black Bottom
    // background: rgb(240,94,35);
    // background: linear-gradient(180deg, rgba(240,94,35,1) 0%, rgba(2,0,36,1) 52%);
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(82,46,118,1) 50%, rgba(0,0,0,1) 80%);
`
const MerchTitleImg = styled.img `
    margin: 0 auto;
    width: 45%;
    @media(max-width:1365px){
        width: 65%;
    }
    @media(max-width:850px){
        width: 80%;
    }
    @media(max-width:600px){
        width: 95%;
    }

`

const MerchButton = styled.button `
    width: 30%;
    margin: 2% auto;
    height:6vh;
    border-radius:10px;
    text-align: center;
    font-size:1.5rem;
    background-color: #522E76;
    color: white;
    border: 3px solid #F05E23;
    @media(max-width:734px){
        width: 45%
    }
    @media(max-width:550px){
        font-size: 1.3rem;
    }
    @media(max-width:500px){
        width: 75%
        font-size:1rem;
    }
    @media(max-width: 450px){
        height: 7vh;
    }
    @media(max-width: 375px){
        height: 9vh;
    }
`
const MerchImg = styled.img `
    border: 5px solid #F05E23;
    border-radius: 50px;
    -webkit-box-shadow: 2px 1px 9px 6px #F05E23; 
    box-shadow: 2px 1px 9px 6px #F05E23;
`
const TwitchDiv = styled.div `
    display:flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 1% 7%;
    border: 5px solid #6441a5;
    padding: 0 2%;
    border-radius: 15px;
    background-color: black;
    // background-image: url(${twitchwall});
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    :hover {
        background-color: #6441a5;
    }
`

const TwitchMainDiv = styled.div`
    background:white;
    background-image: url(${twitchwall});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 1% 0;
    width: 100%;
    // height: 50vh;
    // border-radius: 30px;
`
const TwitchLogo = styled.img`
    width: 5%;
    @media(max-width:1400px){
        width: 7%;
    }
    @media(max-width:1300px){
        width: 9%;
    }
    @media(max-width:1000px){
        width: 11%;
    }
    @media(max-width:800px){
        width:13%;
    }
    @media(max-width:700px){
        width: 15%;
    }
    @media(max-width:600px){
        width: 17%;
    }
    @media(max-width:500px){
        width: 14%;
    }
`

const PatreonDiv = styled.div`
    display: flex;
    flex-direction: row;
    background-image: url(${PodcastPic2});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media(max-width: 700px){
        background-postion: 25% 25%;;
    }

`
const PatreonL = styled.img`
    width: 20%;
    @media(max-width: 1325px){
        display: none;
    }    
    @media(max-width:875px){
        width: 25%;
    }
    @media(max-width:800px){
        width: 30%;
        height: 200px;
    }
`
const PatreonR = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    @media(max-width: 1325px){
        width: 100%;
    }  
`

const PatreonH1 = styled.p `
    color: white; 
    font-size: 2.5rem;
    text-align: center;
    margin: 0 1%;
    font-family: 'Bebas Neue', cursive;
    font-family: 'Dosis', sans-serif;
    @media(max-width:500px){
        font-size: 2rem;

    }
    
`

const PatreonTitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
`

const PatreonButton = styled.button `
    width: 20%;
    margin: 2% auto;
    height:6vh;
    border-radius:10px;
    text-align: center;
    font-size:2rem;
    font-family: 'Bebas Neue', cursive;
    font-family: 'Dosis', sans-serif;
    background-color: #f96854;
    color: #052d49;
    border: none;
    @media(max-width:734px){
        width: 45%
    }
    @media(max-width:500px){
        width: 85%
        font-size:1rem;
        height: 5vh;
    }
    @media(max-width: 450px){
        height: 7vh;
    }
    @media(max-width: 375px){
        height: 9vh;
    }
`
const PatreonLogo1 = styled.img`
    width: 5%;
    @media(max-width:1400px){
        width: 6%;
    }
    @media(max-width:1300px){
        width: 8%;
    }
    @media(max-width:1000px){
        width: 10%;
    }
    @media(max-width:800px){
        width:12%;
    }
    @media(max-width:700px){
        width: 10%;
    }
    @media(max-width:600px){
        width: 11%;
    }
    @media(max-width:500px){
        width: 12%;
    }
    @media(max-width:475px){
        width: 10%;
    }
`

const PatreonH2 = styled.h2 `
    font-family: 'Press Start 2P', cursive;
    font-size: 3rem;
    text-align: center;
    color: white;
    margin: 1%;
    // animation: blinker 3s linear infinite;
    // @keyframes blinker {
    //     50% {
    //         opacity: 0;
    //     }
    // }
    // @media(max-width: 1600px){
    //     font-size: 2.7rem;
    // }
    // @media(max-width: 1000px){
    //     font-size: 2.0rem;
    // }
    @media(max-width:700px){
        font-size: 2.5rem;
        margin: 2% 1%;
    }
    @media(max-width:600px){
        font-size: 2.2rem;
        margin: 2% 1%;
    }
    @media(max-width:500px){
        font-size: 2rem;
    }
    @media(max-width:475px){
        font-size: 1.8rem;
    }
`

//  `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&maxResults=4&order=date&type=video&key=${KEY}`
function Home() {
    const [videos, setVideos] = useState([]);
    const [play] = useSound(joeyisamush);
    const [playlist, setPlaylist] = useState([]);
    AOS.init();
    const history = useHistory();

    // const handleSubTwitch = () => {
    //     history.replace('https://www.twitch.tv/ryandepaulodegenerate/subscribe')
    // }
           
    // useEffect(() => {
    //     axios.get(`https://www.googleapis.com/youtube/v3/channelSections?part=snippet.popularUploads&channelId=${CHANNEL_ID}&`)
    //         .then(res => {
    //             console.log('recent videos', res.data.items)
    //             setVideos(res.data.items)
    //         })
    //         .catch(err => {
    //             console.log('error:', err)
    //         })
    // }, [setVideos])

    // useEffect(() => {
    //     axios.get(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${PLAYLIST_ID}&maxResults=4&order=date&type=video&key=${KEY}`)
    //         .then(res => {
    //             console.log('colossus vids', res.data.items);
    //             setPlaylist(res.data.items)
    //         })
    //         .catch(err => {
    //             console.log('error:', err)
    //         })
    // }, [setPlaylist])
    
    return (
        <div style={{background: 'black'}}>
            <NavBar/>
            <Div>
            <TitleImg src={DepauloTitleGif}/>
              <Button1 type="button" class="btn btn-warning" onClick={play}><span>Joey Is A Mush</span></Button1>

            </Div>
            {/* <div>
                <img src={congrats}/>
            </div> */}
            <DivBoth>
                <DivSide>
                    <H3>Latest Poker Vlog</H3>
                    <MainVid>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/k8nnOQSFHkY?si=EIo_uWK6hOUILcTz" allowfullscreen></iframe>

                    </div>
                    </MainVid> 
                </DivSide>
                <DivSide>
                    <H3>My WSOP Bracelet Win!</H3>
                    <MainVid>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/2aNbWMnIwps" allowfullscreen></iframe>
                    </div>
                    </MainVid> 
                </DivSide>
            </DivBoth>
          {/* This Section is here in case I want to utilize the youtube api again. Currently do no have a use for it with the design i have in mind.
           <H2>Most Recent Youtube Videos</H2>
          <YoutubeDiv>
              <VideosDiv>
                {videos.length !== 0 ? videos.map(item => {
                    return <Card style={{margin: '0.5% 10px'}} id={item.id.videoId}>
                        <Card.Img variant="top" src={item.snippet.thumbnails.medium.url} style={{width:`${item.snippet.thumbnails.medium.width}`, height:`${item.snippet.thumbnails.medium.height}`}}/>
                        <Card.Body>
                            <Card.Title>{item.snippet.title}</Card.Title>
                            <Button href={`https://www.youtube.com/watch?v=${item.id.videoId}`} variant="danger">Watch Video</Button>
                        </Card.Body>
                    </Card>


                    // return <Video id={item.id.videoId} style={{width: '40%'}} href={`https://www.youtube.com/watch?v=${item.id.videoId}`}>
                    //     <h2 style={{color: 'red'}}>{item.snippet.title}</h2>
                    //     <img src={item.snippet.thumbnails.medium.url} href={`https://www.youtube.com/watch?v=${item.id.videoId}`} style={{width:`${item.snippet.thumbnails.medium.width}`}, {height:`${item.snippet.thumbnails.medium.height}`}}/> 
                    // </Video>
                }):<H3 style={{color: 'yellow'}}>Youtube API is being a little bitch...Give it a couple of minutes</H3>}
              </VideosDiv>
          </YoutubeDiv> */}
          
          <YoutubeDiv>
              <H2>My Colossus Run For $208,643</H2>
            <VideosDiv>
                {COLOSSUS_VIDS.map(item => {
                    return <Card style={{margin: '0.5% 10px'}} id={item.snippet.publishedAt}>
                        <Card.Img variant="top" src={item.snippet.thumbnails.medium.url} style={{width:`${item.snippet.thumbnails.medium.width}`, height:`${item.snippet.thumbnails.medium.height}`}}/>
                        <Card.Body>
                            <Card.Title>{item.snippet.title}</Card.Title>
                            <Button href={`https://www.youtube.com/playlist?list=${item.snippet.playlistId}`} variant="primary">Take Me To Playlist</Button>
                        </Card.Body>
                    </Card>
                })}
                
                {/* // <H3 style={{color: 'yellow'}}>Youtube API is being a little bitch...Give it a couple of minutes</H3>} */}
              </VideosDiv>
          </YoutubeDiv>
          <YoutubeDiv710>
          <H2>My Colossus Run For $208,643</H2>
            <VideosDiv>
                
                <Card style={{margin: '0.5% 10px' }} id={COLOSSUS_VIDS[3].snippet.publishedAt}>
                    <Card.Img variant="top" src={COLOSSUS_VIDS[3].snippet.thumbnails.medium.url} style={{width:`${COLOSSUS_VIDS[3].snippet.thumbnails.medium.width}`, height:`${COLOSSUS_VIDS[3].snippet.thumbnails.medium.height}`}}/>
                    <Card.Body>
                        <Card.Title>Full Colossus Playlist (4 Videos)</Card.Title>
                        <Button href={`https://www.youtube.com/playlist?list=${COLOSSUS_VIDS[3].snippet.playlistId}`} variant="primary">Take Me To Playlist</Button>
                    </Card.Body>
                </Card>
                
                
                {/* // <H3 style={{color: 'yellow'}}>Youtube API is being a little bitch...Give it a couple of minutes</H3>} */}
              </VideosDiv>
          </YoutubeDiv710>
          
            <MerchDiv>
                {/* <div class="dark fire">
                    <h1 class="Blazing" contenteditable="true">Check Out My Merch</h1>
                </div> */}
                <MerchTitleImg src={MerchTitleGif}></MerchTitleImg>
                <div style={{display: 'flex', flexDirection:"column", margin:'1% auto'}}>
                    <A3><MerchImg src={merch} /></A3>
                    <MerchButton onClick={() =>  window.location.href='https://ryans-degenerate-store.creator-spring.com/listing/ev-hoodie?product=212&variation=5827&size=1167'}>
                    Check Out All The Styles Here</MerchButton>
                </div>
                
            </MerchDiv>
                
            <PatreonDiv>
                <PatreonL src={PatreonLogo}/>
                <PatreonR>
                    <PatreonTitleDiv>
                        
                        <PatreonH2 style={{color: '#f96854'}}>Support My Patreon!</PatreonH2>
                        <PatreonLogo1 src={PatreonLogo2}/>
                    </PatreonTitleDiv>
                    <PatreonH1>Click the link to check out my <a href="https://www.patreon.com/Depaulo" style={{color: '#f96854'}}>Patreon Page!</a> $5 per month Tier Patreon Supporters (Home Game Champ tier) 
                    will have access to 3 Exclusive Podcast Episodes a Month and $20 per month Patreons Supporters (World Class Player tier) become part of the show by suggesting topics or writing in advice 
                    questions for the podcast!
                    </PatreonH1>
                    <PatreonButton onClick={() =>  window.location.href='https://www.patreon.com/Depaulo'}>Support Here</PatreonButton>
                </PatreonR>
            </PatreonDiv>
            
          <TwitchMainDiv>
                <TwitchTitleDiv>
                    <TwitchH2 style={{color: '#8800be'}}>
                        Twitch
                    <TwitchLogo src={neontwitch}/>
                    </TwitchH2>
                </TwitchTitleDiv>
                <TwitchH1>
                    Throw a sub to my <a href="https://www.twitch.tv/ryandepaulodegenerate" style={{color: '#8800be'}}>Twitch!</a> Subs get a bunch of benefits like no ads, a shoutout during stream, and all of the emotes below to use in chat!
                </TwitchH1>
                <TwitchH1>
                    Also, if you have Amazon Prime, you get a <a href="https://gaming.amazon.com/home" style={{color: 'lightgreen'}}> FREE </a> $4.99 subscription to use on any Twitch Streamer every month. 
                    Click <a href='https://gaming.amazon.com/home' style={{color: '#8800be'}}> Here</a> and go to "activate here" to connect your twitch account, and then use your free sub by clicking 
                    on the Tier 1 button below!
                </TwitchH1>
          <TwitchDiv onClick={() =>  window.location.href='https://www.twitch.tv/ryandepaulodegenerate/subscribe'}>
            <TwitchP1 style={{color: 'white'}}>Tier 1 ($4.99)</TwitchP1>
            <Img src={ryande32} alt="ryande32"/>
            <Img src={ryande33} alt="ryande33"/>
            <Img src={ryande35} alt="ryande35"/>
            <Img src={ryande3C} alt="ryande3C"/>
            <Img src={ryande3Cay} alt="ryande3Cay"/>
            <Img src={ryande3Dad} alt="ryande3Dad"/>
            <Img src={ryande3Luke} alt="ryande3Luke"/>
            <Img src={ryande3M} style={{borderRadius: "0px"}} alt="ryande3M"/>
            <Img src={ryande3P} style={{borderRadius: "0px"}} alt="ryande3P"/>
            <Img src={ryande3Punt} style={{borderRadius: "0px"}} alt="ryande3Punt"/>
            <Img src={ryande3Sanic} style={{borderRadius: "0px"}} alt="ryande3Sanic"/>
            <Img src={ryande3T} alt="ryande3T"/>
            <Img src={ryande3Tu} alt="ryande3Tu"/>
            <Img src={ryande3Wow} alt="ryande3Wow"/>
        </TwitchDiv>
        <TwitchDiv onClick={() =>  window.location.href='https://www.twitch.tv/ryandepaulodegenerate/subscribe'}>
            <TwitchP1 style={{color: 'white'}}>Tier 2 ($9.99)</TwitchP1>
            <Img src={ryande3Y} alt="ryande3Y"/>
            <Img src={ryande34} alt="ryande34"/>
            <Img src={ryande3Angle} style={{borderRadius: "0px"}} alt="ryande3Angle"/>
            <p style={{color: 'white', textAlign: 'bottom'}}>*Includes Tier 1</p>
        </TwitchDiv>
        <TwitchDiv onClick={() =>  window.location.href='https://www.twitch.tv/ryandepaulodegenerate/subscribe'}>
            <TwitchP1 style={{color: 'white'}}>Tier 3 ($24.99)</TwitchP1>
            <Img src={ryande3Stu} alt="ryande3Stu"/>
            <p style={{color: 'white'}}>*Includes Tier 1 & 2</p>
        </TwitchDiv>
        </TwitchMainDiv>
          {/* <ResultsDiv>
          <HendonDiv>
              <H2 style={{color: 'orange'}}>Hendon Mob</H2>
              <img src={Hendon} style={{border: '5px solid orange'}}/>
          </HendonDiv>
          <HendonDiv>
              <H2 style={{color: 'orange'}}>2021 Live Results</H2>
              <img src={Results} style={{border: '5px solid orange'}}/>
          </HendonDiv>
          </ResultsDiv> */}
          <Affiliates>
                <AffiliateH2>Affiliates</AffiliateH2>
                {/* <ACR> */}
                <Card style={{ width: '40rem' }}>
                    <Card.Img variant="top" src={AcrPoker} />
                    <Card.Body>
                        <Card.Title style={{fontSize: '2rem', color: 'blue'}}>ACR Poker</Card.Title>
                        <Card.Text style={{color: 'black'}}>
                        Sign up now using Promo Code: <span style={{color: 'red', fontSize:'1.5rem'}}>Gambler</span>.
                        </Card.Text>
                        <Button variant="primary" href="https://www.americascardroom.eu/online-poker-promotions/welcome-package/">Sign Up Now!</Button>
                    </Card.Body>
                </Card>
          </Affiliates>
          <SocialMediaMain>
                <H2 style={{color: '#87CEFA'}}>Socials</H2>
                <P1 style={{color:"#87CEFA"}}>Throw Ryan Depaulo Degenerate Gambler a follow for updates!</P1>
                <SocialMedia>
                        <A2 href="https://www.youtube.com/user/Whyisjadakiss"><img src={Youtube}/></A2>
                        <A1 href="https://www.twitch.tv/ryandepaulodegenerate">
                            <img src={Twitch}/>
                        </A1>
                        <A1 href="https://twitter.com/depaulo_ryan"><img src={Twitter}/></A1>
                        <A1 href="https://www.instagram.com/ryan_depaulo/"><img src={Instagram}/></A1>
                </SocialMedia>
          </SocialMediaMain>
        </div>

    )
}
export default Home;